import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store'
import echarts from 'echarts'
import Vant from 'vant'
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.prototype.$echarts = echarts
Vue.prototype.$title = '管理培训矩阵'
Vue.prototype.$staff = '员工'
Vue.prototype.$manager = '主管/经理'
Vue.prototype.$project = '管理'

Vue.config.productionTip = false

Vue.directive('myTitle', {
  inserted: function (el, binding) {
    const { value } = binding
    // 方式1，直接给v-myTitle标签的 data-title 属性赋值
    if (el.dataset.title) {
      document.title = el.dataset.title
    }
    // 方式2，通过指令传参（{{xxx}}）
    else if (value && value.title) {
      document.title = value.title
    }
  },
  update (el, binding) {
    const { value } = binding
    if (el.dataset.title) {
      document.title = el.dataset.title
    } else if (value && value.title) {
      document.title = value.title
    }
  }
})

// 初始化Vuex状态管理中的登录状态
store.state.token = sessionStorage.getItem("token")

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
